import * as React from "react";

const About = () => {
  return (
    <div id="section-about" className="center page-section">
      <div className="container clearfix">
        <h2
          className="mx-auto bottommargin font-body"
          style={{
            maxWidth: "700px",
            fontSize: "40px",
            textTransform: "uppercase",
          }}
        >
          à propos de nous
        </h2>
        <div className="row">
          <div className="col-md-9 col-12 text-left mermez-about">
            <p className="lead bottommargin" style={{ maxWidth: "800px" }}>
              Le projet "MERMEZ Lab" est un laboratoire de pointe qui se
              consacre à la transformation des déchets papiers en matériaux de
              construction innovants.
            </p>
            <p className="lead bottommargin">
              Nous recyclons les déchets papiers tels que les offset, cartons,
              magazines et autres pour créer des plaques de revêtement, des
              cloisons d'intérieur et des produits décoratifs de haute qualité.
            </p>
            <p className="lead bottommargin">
              Avec MERMEZ Lab, nous visons à réduire les déchets de papier tout
              en créant des matériaux durables pour la construction. Nous
              croyons que l'innovation ne se limite pas aux technologies, mais
              qu'elle peut également être trouvée dans la manière dont nous
              utilisons les matériaux de notre environnement.
            </p>
            <p className="lead bottommargin">
              Rejoignez-nous dans notre mission pour un avenir durable, où nous
              transformons les déchets en trésors.
            </p>
          </div>
          <div className="col-md-3 col-12 d-flex">
            <img
              className="align-self-sm-center"
              src="/images/logo.png"
              alt="MermezLab"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
