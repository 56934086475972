import * as React from "react";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

const Portfolio = () => {
  return (
    <div id="section-works" className="pt-0">
      <div className="section m-0 pb-0">
        <div className="clearfix">
          <div className="container center">
            <h2
              className="mx-auto bottommargin font-body"
              style={{
                fontSize: "40px",
                textTransform: "uppercase",
              }}
            >
              Nos produits
            </h2>
            <div className="row">
              <div className="col-12 text-left">
                <p className="lead bottommargin">
                  Nous sommes comme des magiciens qui transforment des bouts de
                  papiers en véritables œuvres d'art ! Avec des couleurs
                  standard comme le blanc cassé, le multicolore et le marron,
                  nous créons des revêtements muraux solides, légers et avec un
                  excellent potentiel d'isolation acoustique.
                </p>
                <p className="lead bottommargin">
                  Imaginez des murs recouverts d'une belle finition en papier
                  recyclé, des produits déco uniques comme des paravents
                  mobiles, des présentoirs et des tabourets, tous fabriqués à
                  partir des déchets tels que les magazines et les journaux
                  recyclés, offrant une touche de personnalité à n'importe
                  quelle pièce. des, légers et avec un excellent potentiel
                  d'isolation acoustique.
                </p>
                <p className="lead bottommargin">
                  Pour les séparations d'intérieur, nous utilisons des plaques
                  montées sur des rails en aluminium, pour diviser les espaces
                  avec élégance.
                  <br />
                  Nous sommes des experts dans la transformation des déchets en
                  trésors ! Rejoignez notre révolution écologique et contribuez
                  à un avenir durable, brique par brique.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-6 mb-4">
              <Carousel
                zIndexAtMax={4000}
                images={[
                  { src: "/images/portfolio/vert/1.jpg" },
                  { src: "/images/portfolio/vert/2.jpg" },
                  { src: "/images/portfolio/vert/3.jpg" },
                ]}
                style={{
                  width: "100%",
                }}
                shouldMaximizeOnClick
                className="carousel-mermez"
              />
            </div>
            <div className="col-md-4 col-sm-6 mb-4">
              <Carousel
                zIndexAtMax={4000}
                images={[
                  { src: "/images/portfolio/bleu/1.jpg" },
                  { src: "/images/portfolio/bleu/2.jpg" },
                  { src: "/images/portfolio/bleu/3.jpg" },
                ]}
                style={{
                  width: "100%",
                }}
                shouldMaximizeOnClick
                className="carousel-mermez"
              />
            </div>
            <div className="col-md-4 col-sm-6 mb-4">
              <Carousel
                zIndexAtMax={4000}
                images={[
                  { src: "/images/portfolio/jaune/1.jpg" },
                  { src: "/images/portfolio/jaune/2.jpg" },
                ]}
                style={{
                  width: "100%",
                }}
                shouldMaximizeOnClick
                className="carousel-mermez"
              />
            </div>
            <div className="col-md-4 col-sm-6 mb-4">
              <Carousel
                zIndexAtMax={4000}
                images={[
                  { src: "/images/portfolio/marron/1.jpg" },
                  { src: "/images/portfolio/marron/2.jpg" },
                ]}
                style={{
                  width: "100%",
                }}
                shouldMaximizeOnClick
                className="carousel-mermez"
              />
            </div>
            <div className="col-md-4 col-sm-6 mb-4">
              <Carousel
                zIndexAtMax={4000}
                images={[
                  { src: "/images/portfolio/orange/1.jpg" },
                  { src: "/images/portfolio/orange/2.jpg" },
                  { src: "/images/portfolio/orange/3.jpg" },
                ]}
                style={{
                  width: "100%",
                }}
                shouldMaximizeOnClick
                className="carousel-mermez"
              />
            </div>
            <div className="col-md-4 col-sm-6 mb-4">
              <Carousel
                zIndexAtMax={4000}
                images={[
                  { src: "/images/portfolio/rouge/1.jpg" },
                  { src: "/images/portfolio/rouge/2.jpg" },
                ]}
                style={{
                  width: "100%",
                }}
                shouldMaximizeOnClick
                className="carousel-mermez"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
