import * as React from "react";
import { withPrefix } from "gatsby";

const Slider = () => {
  return (
    <section
      id="slider"
      className="slider-element slider-parallax min-vh-100"
      style={{
        background: `url(${withPrefix(
          "/images/slider/full/1.jpg"
        )}) center center`,
        backgroundSize: "cover",
      }}
    >
      <div className="slider-inner">
        <div className="vertical-middle slider-element-fade">
          <div className="container heading-wrapper-mermez">
            {/* <div className="heading-logo-mezmez">
              <img src="/images/logo-white.png" alt="MermezLab" />
            </div> */}
            <div className="heading-block border-bottom-0 mb-0 heading-mezmez">
              <h1>
                <div
                  className="text-rotater"
                  data-separator="|"
                  data-rotate="flipInX"
                  data-speed="3500"
                >
                  Changer votre manière <br /> de traiter vos <br /> déchets de
                  papiers.
                  <span className="t-rotate">
                    Recyclage papier|Nouveau matériau recyclé|Laboratoire de
                    recyclage
                  </span>
                </div>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Slider;
