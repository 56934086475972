import * as React from "react";

const Footer = () => {
  return (
    <footer id="footer" className="border-0">
      <div className="container center">
        <div id="section-contact" className="pt-0">
          <div className="row mx-0 align-items-stretch">
            <div className="col-lg-12 col-md-12">
              <div className="col-padding">
                <div className="row mermez-footer-content">
                  <div className="col mezrmez-footer-email">
                    <img src="/images/logo-horizontal.png" alt="MermezLab" />
                    <div>
                      <abbr title="Email">
                        <strong>Email: </strong>
                      </abbr>
                      <a href="mailto:mermezlab@mermezlab.com">
                        mermezlab@mermezlab.com
                      </a>
                    </div>
                  </div>
                  <div className="col">
                    <h3 className="font-body fw-bolder ls1 text-orange m-0">
                      Suivez Nous
                    </h3>
                    <div className="col mezrmez-social">
                      <a
                        aria-label="notre page instagram"
                        href="https://www.instagram.com/mermez_lab/"
                        target="blank"
                        rel=""
                        className="social-icon si-rounded si-large"
                      >
                        <i className="icon-instagram"></i>
                        <i className="icon-instagram"></i>
                      </a>
                      <a
                        aria-label="notre page facebook"
                        href="#"
                        target="blank"
                        className="social-icon si-rounded si-large"
                      >
                        <i className="icon-facebook"></i>
                        <i className="icon-facebook"></i>
                      </a>
                      <a
                        aria-label="notre page linkedin"
                        href="#"
                        target="blank"
                        className="social-icon si-rounded si-large"
                      >
                        <i className="icon-linkedin"></i>
                        <i className="icon-linkedin"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="copyrights">
        <div className="container center clearfix">
          Copyrights MezmezLab {new Date().getFullYear()} | All Rights Reserved
        </div>
      </div>
    </footer>
  );
};

export default Footer;
