import React, { useEffect, useState } from "react";

const Header = () => {
  const [shouldStick, setShouldStick] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  /* Method that will fix header after a specific scrollable */
  const isSticky = () => {
    const header = document.querySelector("#header");
    if (header) {
      const scrollTop = window.scrollY;
      scrollTop >= 250 ? setShouldStick(true) : setShouldStick(false);
    }
  };

  return (
    <header
      id="header"
      className={`full-header transparent-header border-full-header header-size-custom ${
        shouldStick && " sticky-header secondary-color"
      }`}
      data-sticky-shrink="false"
    >
      <div id="header-wrap">
        <div className="container">
          <div className="header-row">
            <div id="logo">
              <a
                href="index.html"
                className="standard-logo white"
                data-dark-logo="/images/logo-horizontal.png"
              >
                <img src="/images/logo-horizontal-white.png" alt="MermezLab" />
              </a>
              <a
                href="index.html"
                className="standard-logo dark"
                data-dark-logo="/images/logo-horizontal.png"
              >
                <img src="/images/logo-horizontal.png" alt="MermezLab" />
              </a>
            </div>

            <div id="primary-menu-trigger">
              <svg className="svg-trigger" viewBox="0 0 100 100">
                <path d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"></path>
                <path d="m 30,50 h 40"></path>
                <path d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"></path>
              </svg>
            </div>

            <nav className="primary-menu">
              <ul
                className="one-page-menu menu-container"
                data-easing="easeInOutExpo"
                data-speed="1250"
                data-offset="65"
              >
                <li className="menu-item">
                  <a href="#" className="menu-link" data-href="#wrapper">
                    <div>Acceuil</div>
                  </a>
                </li>
                <li className="menu-item">
                  <a href="#" className="menu-link" data-href="#section-works">
                    <div>Articles</div>
                  </a>
                </li>
                <li className="menu-item">
                  <a href="#" className="menu-link" data-href="#section-about">
                    <div>A propos</div>
                  </a>
                </li>
                <li className="menu-item">
                  <a href="#" className="menu-link" data-href="#section-team">
                    <div>ÉQUIPE</div>
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    href="#"
                    className="menu-link"
                    data-href="#section-contact"
                  >
                    <div>Contact</div>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="header-wrap-clone"></div>
    </header>
  );
};

export default Header;
