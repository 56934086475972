import * as React from "react";
import Helmet from "react-helmet";
import type { HeadFC } from "gatsby";
import "../css/bootstrap.css";
import "../css/style.css";
import "../css/swiper.css";
import "../css/onepage.css";
import "../css/dark.css";
import "../css/font-icons.css";
import "../css/et-line.css";
import "../css/animate.css";
import "../css/magnific-popup.css";
import "../css/fonts.css";
import "../css/custom.css";
import Header from "../components/layout/header";
import Slider from "../components/slider";
import Portfolio from "../components/portfolio";
import About from "../components/about";
import Team from "../components/team";
import Footer from "../components/layout/footer";
import useScript from "../hooks/useScript";
import { withPrefix } from "gatsby";

const IndexPage = () => {
  useScript(withPrefix("/js/jquery.js"));
  useScript(withPrefix("/js/plugins.min.js"));
  useScript(withPrefix("/js/functions.js"));

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "fr",
        }}
      >
        <body className="stretched" />
        <meta
          name="description"
          content="MERMEZ Lab est un laboratoire de valorisation de déchets de papiers en
          plaques de revêtement mural, cloisons d'intérieurs et des produits
          décoratifs."
        />
      </Helmet>
      <div id="wrapper" className="clearfix">
        <Header />
        <Slider />
        <Portfolio />
        <About />
        <Team />
        <Footer />
      </div>
      <div id="gotoTop" className="icon-angle-up"></div>
    </>
  );
};

export default IndexPage;

export const Head: HeadFC = () => (
  <>
    <title>Mermez Lab</title>
  </>
);
