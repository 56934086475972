import * as React from "react";

const Team = () => {
  return (
    <div id="section-team" className="center">
      <div className="section m-0">
        <div className="container clearfix">
          <div className="heading-block center">
            <h2
              className="mx-auto bottommargin font-body"
              style={{
                fontSize: "40px",
                textTransform: "uppercase",
                fontWeight: "600",
              }}
            >
              notre équipe
            </h2>
            <span>
              Notre équipe est composée de deux membres passionnés et dévoués:
              Zeineb et Mermez.
              <br /> Ensemble, nous formons une équipe dévouée et passionnée par
              notre projet, qui travaille pour une société plus durable en
              utilisant des matériaux écologiques. En tant qu'équipe, nous
              sommes engagés à offrir un service exceptionnel à nos clients.
            </span>
          </div>
          <div className="row col-mb-50 mb-0">
            <div className="col-lg-6">
              <div className="team team-list align-items-center">
                <div className="team-image flex-column align-items-center">
                  <img src="/images/team/3.jpg" alt="Zeineb Blaiech" />
                  <div className="team-title">
                    <h3>Zeineb Blaiech</h3>
                    <span>Fondatrice</span>
                  </div>
                  <div className="team-content">
                    <p>
                      Zeineb est la CEO et designer en chef. Elle est
                      responsable de la direction générale de l'entreprise et de
                      la conception des produits. Avec une grande expérience en
                      design et une passion pour l'environnement, elle dirige
                      l'entreprise vers l'innovation et la durabilité.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="team team-list align-items-center">
                <div className="team-image">
                  <img
                    src="/images/team/2.jpg"
                    alt="Mezmez"
                    id="mermez-image"
                  />
                  <div className="team-title">
                    <h3>Mermez</h3>
                    <span>Directeur Artistique & Communication</span>
                  </div>
                  <div className="team-content">
                    <p>
                      Mermez, notre directeur artistique et responsable de la
                      communication, est responsable de la création de notre
                      image de marque. Il a une grande expérience en design
                      graphique et en communication, il est un véritable créatif
                      qui apporte une touche artistique à tous nos projets. Il
                      est également chargé de la communication et de la
                      promotion de notre projet auprès du public et des
                      partenaires.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="heading-block center">
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
